import { PaletteOptions } from "@mui/material"

interface OtherColorTypes {
    beige: string,
    lightBlue: string,
    plum: string,
    navigation: string,
    background: {
        heroPanel: string
        introPanel: string,
        cardPanel: string,
        footer: string,
        primaryHeadline: string
        secondaryHeadline: string
    }
}

declare module "@mui/material/styles/createPalette" {
    interface SimplePaletteColorOptions {
        states?: {
            containedHoverBackground?: string
            containedDisabledBackground?: string
        }
    }
    interface PaletteColor {
        states?: {
            containedHoverBackground?: string
            containedDisabledBackground?: string
        }
    }
    interface PaletteOptions {
        other: OtherColorTypes
    }
}

export const GREY_TONES = {
    100: '#DCDCE0',
    200: '#C2C1C7',
    300: '#B1B1B1',
    400: '#A7A7AF',
    500: '#707070',
}

const palette: PaletteOptions = {
    primary: {
        light: '#EDEDff',
        main: '#5956E9',
        dark: '#302F6F',
        states: {
            containedHoverBackground: '#2F2BE3',
            containedDisabledBackground: GREY_TONES[300]
        }
    },
    secondary: {
        light: '#FFED90',
        main: '#FFE318',
        dark: '#D6B401',
        states: {
            containedHoverBackground: '#D6B401',
            containedDisabledBackground: GREY_TONES[300]
        }
    },
    grey: GREY_TONES,
    common: {
        white: '#FFFFFF',
        black: '#0B0A1F'
    },
    success: {
        main: '#52ae6c'
    },
    error: {
        main: '#C62828',
    },
    other: {
        beige: '#FFEDDC',
        lightBlue: '#ECF4FB',
        navigation: '#383887',
        plum: '#975468',
        background: {
            heroPanel: 'linear-gradient(133.53deg, #F2F6F9 30.42%, rgba(242, 246, 249, 0) 76.96%)',
            footer: 'rgba(89, 86, 233, 0.05)',
            primaryHeadline: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 40.92%), linear-gradient(360deg, #5C5AB5 30%, rgba(92, 90, 181, 0) 66.67%), #5956E9',
            secondaryHeadline: 'linear-gradient(180deg, #FFE318 0%, #FF9900 100%)',
            introPanel: "linear-gradient(180deg, rgba(255, 255, 255, 0) 78.44%, #FFFFFF 95.1%), #F8F5DF",
            cardPanel: "linear-gradient(180deg, rgba(203, 234, 255, 0) 15.66%, rgba(161, 134, 205, 0.4) 71.67%), #C0DAEC",

        }
    }

}

export default palette