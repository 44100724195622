import { BreakpointsOptions } from "@mui/material";

const breakpoints: BreakpointsOptions = {
    values: {
        xs: 0,
        sm: 480,
        md: 768,
        lg: 1280,
        xl: 1536,
    }
}

export default breakpoints