import { TypographyOptions } from "@mui/material/styles/createTypography"

const LUCKIEST_GUY_FAMILY = ["'Luckiest Guy'", 'cursive'].join(',')
const POPPINS_FAMILY = ['"Poppins"', "sans-serif"].join(',')

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        headline: true;
    }
}

interface ExtendedTypographyOptions extends TypographyOptions {
    headline: React.CSSProperties,
}

const typography: ExtendedTypographyOptions = {
    fontFamily: POPPINS_FAMILY,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,

    headline: {
        fontFamily: LUCKIEST_GUY_FAMILY,
        fontWeight: 400,
    },

    button: {
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '30px'
    }
}

export default typography